import { classes } from './Sidebar.st.css';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import { FlowElements, useFlow } from '../../Hooks/useFlow';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

const Sidebar: React.FC<SidebarProps> = ({
  viewModel: { sidebarTitle, bookingDetailsViewModel },
}) => {
  const sidebarRef = useFlow(FlowElements.SIDEBAR);

  return (
    <div ref={sidebarRef} data-hook="sidebar" className={classes.root}>
      <SectionHeader title={sidebarTitle} />
      <BookingDetails viewModel={bookingDetailsViewModel} />
    </div>
  );
};

export default Sidebar;
